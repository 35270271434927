<template>
    <div class="">
        <page-loading-view v-if="!isLoaded" />
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import PageLoadingView from "@Platon/components/pages/PageLoadingView"

export default {
    name: "AsyncPage",
    data: {
        isLoading: true
    },
    components: { PageLoadingView },
    computed: {
        ...mapGetters({
            menu: "platon/menu"
        })
    },
    watch: {
        menu: {
            handler(v) {
                if (v.length > 0) {
                    const homePage = v.find((i) => i.isHomepage)
                    if (homePage) {
                        this.$router.push(homePage.link)
                    } else {
                        this.$router.push({ name: "profile-page" })
                    }

                    this.isLoading = false
                }
            },
            deep: true
        }
    }
}
</script>
